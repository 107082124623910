<template>
  <b-img
    class="img-logo"
    :src="sideImg"
    alt="Login"
  />
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'

export default {
  name: 'Logo',
  components: {
    BImg,
  },
  data() {
    return {
      sideImg: require('@/assets/images/logo/logo.png'),
      // validation rulesimport store from '@/store/index'
    }
  },
}
</script>

<style lang="scss">
.img-logo {
  width: 200px;
}
</style>
